import { FC, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  TextField,
} from '@mui/material';

import { getMeetRecords } from '../../api/meetRecords';
import Row from './Row';
import { DEFAULT_RECORDS } from './constants';
import { useLocation } from 'react-router-dom';

const ROWS_COUNT = 10;

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const Records: FC = () => {
  const query = useQuery();
  const history = useHistory();

  const [records, setRecords] = useState<any[]>(DEFAULT_RECORDS);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState<string>(
    query.get('roomId') || ''
  );

  useEffect(() => {
    getMeetRecords({
      page: page + 1,
      roomId: searchText,
    }).then(
      (result: any) => {
        setRecords(result.docs || []);
        setTotal(result.totalDocs || 0);
      },
      () => history.push('/')
    );
  }, [page, searchText, history]);

  return (
    <Container style={{ maxHeight: '100%', overflowY: 'scroll' }}>
      <Paper>
        <Typography variant="h3" gutterBottom style={{ margin: 10 }}>
          Записи встреч
        </Typography>
      </Paper>

      <div>
        <TextField
          label="Поиск по RoomId"
          value={searchText}
          onChange={(e: any) => {
            setSearchText(e.target.value);
            setPage(0);
          }}
        />
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Record ID</TableCell>
              <TableCell>Room ID</TableCell>
              <TableCell>Начало записи</TableCell>
              <TableCell>Окончание записи</TableCell>
              <TableCell>Длительность</TableCell>
              <TableCell>Статус</TableCell>
              <TableCell>Действие</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map((record) => (
              <Row key={record._id} record={record} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[ROWS_COUNT]}
        component="div"
        count={total}
        rowsPerPage={ROWS_COUNT}
        page={page}
        onPageChange={(event: unknown, newPage: number) => setPage(newPage)}
      />
    </Container>
  );
};

export default Records;

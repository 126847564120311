import { useEffect, VFC } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Box,
  Typography,
  Container,
} from '@mui/material';
import { LockOutlined } from '@mui/icons-material';

import { RootState } from '../../store';
import { login } from '../../store/auth';
import { useTranslation } from 'react-i18next';

const Login: VFC = () => {
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.auth.user);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const loginData: any = {
      username: String(data.get('username')),
      password: String(data.get('password')),
    };

    dispatch<any>(login(loginData));
  };

  useEffect(() => {
    if (user) history.push('/');
  }, [history, user]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5" align="center">
          {t('sign_in')}
        </Typography>
        <Typography component="h5" variant="subtitle2" align="center">
          {t('sign_in_note')}{' '}
          <a href="https://app.progkids.com" target="_blank" rel="noreferrer">
            app.progkids.com
          </a>
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Email"
            name="username"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label={t('password')}
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button type="submit" fullWidth variant="contained">
            {t('login')}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;

import { useCallback, useEffect, useState, VFC } from 'react';
import { JitsiLocalTrack } from '../../../../types/Jitsi';
import { Audio as AudioElem } from './styled';

const Audio: VFC<{ track: JitsiLocalTrack | undefined | null }> = ({
  track,
}) => {
  const [element, setElement] = useState();

  const handleElement = useCallback((node) => {
    setElement(node);
  }, []);

  useEffect(() => {
    if (track && element) track.attach(element);

    return () => {
      if (track && element) track.detach(element);
    };
  }, [track, element]);

  return (
    <AudioElem
      playsInline
      autoPlay
      ref={handleElement}
      style={{ transition: '0' }}
    />
  );
};

export default Audio;

import styled from 'styled-components';

export const Root = styled.div`
  width: 320px;
  height: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const MessagesSection = styled.div`
  overflow-y: auto;
`;
export const FormSection = styled.div`
  margin-bottom: 20px;
`;

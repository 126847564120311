import { JitsiLocalTrack } from '../../types/Jitsi';

export interface UseRoomProps {
  roomId: string;
}

export class Tracks {
  constructor(
    public videoTrack?: JitsiLocalTrack | null,
    public microTrack?: JitsiLocalTrack | null
  ) {}

  public get hasVideoAccess(): boolean {
    return !!this.videoTrack;
  }

  public get hasMicroAccess(): boolean {
    return !!this.microTrack;
  }

  public get isVideoEnabled(): boolean {
    return this.videoTrack ? !this.videoTrack.isMuted() : false;
  }

  public get isMicroEnabled(): boolean {
    return this.microTrack ? !this.microTrack.isMuted() : false;
  }
}

export interface Client {
  userId: string;
  jitsiUserId: string;
  tracks: Tracks;
  isScreenSharing: boolean;
  userMeetName: string;
  isElectron: boolean;
}

export interface ClientsState {
  [key: string]: Client;
}

import Jitsi from 'lib-jitsi-meet';

import { JitsiLocalTrack } from '../types/Jitsi';

export const getJitsiScreenTrack = async (options: any) => {
  const tracks: JitsiLocalTrack[] = await Jitsi.createLocalTracks({
    devices: ['desktop'],
    ...options,
  });

  tracks.find((x: JitsiLocalTrack) => x.type === 'audio')?.dispose();

  return tracks.find((x: JitsiLocalTrack) => x.type === 'video');
};

export const getJitsiCameraTrack = async ({ cameraDeviceId = 'default' }) => {
  const tracks: JitsiLocalTrack[] = await Jitsi.createLocalTracks({
    devices: ['video'],
    cameraDeviceId,
  });

  return tracks.find((x: JitsiLocalTrack) => x.type === 'video');
};

export const getJitsiMicroTrack = async ({ micDeviceId = 'default' }) => {
  const tracks: JitsiLocalTrack[] = await Jitsi.createLocalTracks({
    devices: ['audio'],
    micDeviceId,
  });

  return tracks.find((t: JitsiLocalTrack) => t.type === 'audio');
};

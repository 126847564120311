import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useState, FC, useEffect } from 'react';
import { getRoomStats } from '../../api/meetRecords';
import moment from 'moment';
import { MeetRecord } from '../../api/models/meetRecords';

interface Props {
  record: MeetRecord;
}

interface Details {
  _id: string;
  action: string;
  roomId: string;
  userId?: string;
  createdAt: string;
  data?: any;
}

const Logs: FC<Props> = ({ record }) => {
  const [details, setDetails] = useState<Details[]>([]);

  useEffect(() => {
    getRoomStats(record.roomId).then(setDetails);
  }, [record.roomId]);

  const start = moment(record.recordStartDate).subtract(1, 'minutes');
  const end = moment(record.recordEndDate);

  const printTime = (createdAt: string) => {
    const duration = moment.duration(moment(createdAt).diff(start));

    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    const pad = (val: any) => String(val).padStart(2, '0');
    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  };

  return (
    <Table>
      <TableBody>
        {details
          .filter((details) => {
            const date = moment(details.createdAt);
            return date.isAfter(start) && date.isBefore(end);
          })
          .map((detail) => (
            <TableRow key={`${detail._id}`}>
              <TableCell>
                {moment(detail.createdAt).format('LTS')} (
                {printTime(detail.createdAt)})
              </TableCell>
              <TableCell>{detail.action}</TableCell>
              <TableCell>{detail.userId}</TableCell>
              <TableCell>{(detail.data || {}).userMeetName}</TableCell>
              <TableCell>
                <pre>{JSON.stringify(detail.data || {}, null, 4)}</pre>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

export default Logs;

import { VFC } from 'react';
import { IconButton } from '@mui/material';

import Tooltip from '../Tooltip';
import { ControlButtonProps } from './interfaces';
import { RED_BUTTON } from './constants';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  animatedItem: {
    animation: `$animatedItem 800ms`,
    animationIterationCount: '3',
  },
  '@keyframes animatedItem': {
    '50%': {
      border: '5px solid yellow',
      borderRadius: '15px',
      transform: 'scale(2)',
    },
  },
}));

const ControlButton: VFC<ControlButtonProps> = ({
  enabledIcon,
  disabledIcon,
  enabledColors,
  disabledColors = RED_BUTTON,
  loadingColors,
  enabled = true,
  loading,
  tooltip,
  animate,
  ...props
}) => {
  let colors = enabled ? enabledColors : disabledColors;
  const Icon = enabled ? enabledIcon : disabledIcon;
  const classes = useStyles();

  if (loading && loadingColors) {
    colors = loadingColors;
  }

  const Button = Icon ? (
    <IconButton
      style={{
        border: `2px solid ${colors.border}`,
        background: colors.background,
      }}
      {...props}
    >
      <Icon
        style={{ boxSizing: 'border-box' }}
        htmlColor={colors.icon}
        className={animate ? classes.animatedItem : ''}
      />
    </IconButton>
  ) : (
    <></>
  );

  return tooltip ? (
    <Tooltip arrow title={tooltip}>
      {Button}
    </Tooltip>
  ) : (
    Button
  );
};

export default ControlButton;

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import Bugsnag from '@bugsnag/js';

import { User } from '../types/user';

import { setUserMeetName, setUserId as setRoomUserId } from './room';
import { setUser as setLoggerUser } from '../utils/debug';
import { userAPI } from '../api/user';

export interface AuthState {
  userId: string;
  user: User | null;
  isFetchAuthCheck: boolean;
  devices: {
    micro: MediaDeviceInfo[];
    camera: MediaDeviceInfo[];
    audio: MediaDeviceInfo[];
    currentMicro: string;
    currentCamera: string;
    currentAudio: string;
  };
}

const initialState: AuthState = {
  userId: `unAuth-${new Date().valueOf().toString()}`,
  user: null,
  isFetchAuthCheck: true,
  devices: {
    micro: [],
    camera: [],
    audio: [],
    currentMicro: 'default',
    currentAudio: 'default',
    currentCamera: 'default',
  },
};

const receiveAuth = createAsyncThunk(
  'user/receiveAuth',
  async (_, { dispatch }) => {
    dispatch(setIsFetchAuthCheck(true));
    try {
      const user = await userAPI.isUserLoggedIn();
      dispatch(updateUser(user));
    } catch (error) {
      dispatch(updateUser(null));
      Bugsnag.notify(error as any);
    }
    dispatch(setIsFetchAuthCheck(false));
  }
);

const login = createAsyncThunk(
  'user/login',
  async (data: any, { dispatch }) => {
    try {
      const res = await userAPI.login(data);
      dispatch(updateUser(res));
    } catch (error: any) {
      Bugsnag.notify(error);
      toast.error(error.message);
    }
  }
);

const logout = createAsyncThunk('user/logout', async (_, { dispatch }) => {
  userAPI
    .logout()
    .catch(Bugsnag.notify)
    .finally(() => dispatch(updateUser(null)));
});

const updateUser = createAsyncThunk(
  'user/setUser',
  async (payload: User | null, { dispatch }) => {
    const { _id, realname, email } = payload || {
      _id: `unAuth-${Math.ceil(Math.random() * 10 ** 5)}`,
    };

    setLoggerUser(_id, realname || _id);
    Bugsnag.setUser(_id, email, realname);

    dispatch(setUserId(_id));
    dispatch(setRoomUserId(_id));
    if (realname) dispatch(setUserMeetName(realname));

    dispatch(setUser(payload));
  }
);

export const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
    },
    setIsFetchAuthCheck: (state, action: PayloadAction<boolean>) => {
      state.isFetchAuthCheck = action.payload;
    },
    setMicroDevices: (state, action: PayloadAction<MediaDeviceInfo[]>) => {
      state.devices.micro = action.payload;
    },
    setCameraDevices: (state, action: PayloadAction<MediaDeviceInfo[]>) => {
      state.devices.camera = action.payload;
    },
    setAudioDevices: (state, action: PayloadAction<MediaDeviceInfo[]>) => {
      state.devices.audio = action.payload;
    },
    setCurrentMicroDevice: (state, action: PayloadAction<string>) => {
      state.devices.currentMicro = action.payload;
    },
    setCurrentCameraDevice: (state, action: PayloadAction<string>) => {
      state.devices.currentCamera = action.payload;
    },
    setCurrentAudioDevice: (state, action: PayloadAction<string>) => {
      state.devices.currentAudio = action.payload;
    },
  },
});

export const {
  setUserId,
  setIsFetchAuthCheck,
  setMicroDevices,
  setCameraDevices,
  setAudioDevices,
  setCurrentMicroDevice,
  setCurrentCameraDevice,
  setCurrentAudioDevice,
  setUser,
} = authSlice.actions;
export { receiveAuth, login, logout, updateUser };

export default authSlice.reducer;

import { VFC } from 'react';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ControlButton from '../UI/ControlButton';

import { RED_BUTTON, BLACK_BUTTON } from '../UI/ControlButton/constants';
import { ControlPanelProps } from './interfaces';
import { ButtonWrap } from './styled';
import { useTranslation } from 'react-i18next';

const ControlPanel: VFC<ControlPanelProps> = ({
  cameraEnabled,
  microEnabled,
  onToggleCamera,
  onToggleMicro,
  onToggleSettings,
}) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        margin: '20px auto',
        width: 'fit-content',
      }}
    >
      {Boolean(onToggleMicro) && (
        <ButtonWrap>
          <ControlButton
            onClick={onToggleMicro}
            enabledIcon={MicIcon}
            disabledIcon={MicOffIcon}
            enabledColors={BLACK_BUTTON}
            disabledColors={RED_BUTTON}
            enabled={microEnabled || false}
            tooltip={microEnabled ? t('micro_turn_off') : t('micro_turn_on')}
          />
        </ButtonWrap>
      )}
      {Boolean(onToggleCamera) && (
        <ButtonWrap>
          <ControlButton
            onClick={onToggleCamera}
            enabledIcon={VideocamIcon}
            disabledIcon={VideocamOffIcon}
            enabledColors={BLACK_BUTTON}
            disabledColors={RED_BUTTON}
            enabled={cameraEnabled || false}
            tooltip={cameraEnabled ? t('camera_turn_off') : t('camera_turn_on')}
          />
        </ButtonWrap>
      )}
      {Boolean(onToggleSettings) && (
        <ButtonWrap>
          <ControlButton
            onClick={onToggleSettings}
            enabledIcon={MoreVertIcon}
            disabledIcon={MoreVertIcon}
            enabledColors={BLACK_BUTTON}
            disabledColors={BLACK_BUTTON}
            enabled={true}
            tooltip={t('settings')}
          />
        </ButtonWrap>
      )}
    </div>
  );
};

export default ControlPanel;

import axios from './index';
import { MeetRecord } from './models/meetRecords';

interface GetMeetRecordsReq {
  page: number;
  roomId: string;
}
export const getMeetRecords = async (
  options: GetMeetRecordsReq
): Promise<MeetRecord[]> => {
  const { data } = await axios.post('/api/records', options);

  return data;
};

export const getMeetRecord = async (recId: string): Promise<MeetRecord> => {
  const { data } = await axios.get(`/api/records/${recId}`);

  return data;
};

export const getRoomStats = async (id: string): Promise<any> => {
  const { data } = await axios.get(`/api/room/${id}`);

  return data;
};

export const getMergedVideo = async (id: string): Promise<any> => {
  const { data } = await axios.get(`/api/records/${id}/merge`);

  return data;
};

export const mergedVideo = async (id: string, options: any): Promise<any> => {
  const { data } = await axios.post(`/api/records/${id}/merge`, options);

  return data;
};

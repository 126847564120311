import { useCallback, useEffect, useState } from 'react';

import { roomSocket as roomSocketInstance } from '../../socket';

import {
  Coordinate,
  OnStartPaintData,
  PaintType,
  UsePaintProps,
} from './interfaces';
import { drawLine } from './utils';

const clearInterval = 2; //in sec

const useSendPaint = ({
  canvasRef,
  paintType,
  receiveUserId,
}: UsePaintProps) => {
  const [roomSocket] = useState(roomSocketInstance);

  const [isPainting, setIsPainting] = useState(false);
  const [mousePosition, setMousePosition] = useState<Coordinate | undefined>(
    undefined
  );
  const [clearTimeoutId, setClearTimeoutId] = useState<NodeJS.Timeout | null>(
    null
  );

  const getCoordinates = useCallback(
    (event: any): Coordinate => {
      const res = { x: 0, y: 0 };
      if (!canvasRef.current) {
        return res;
      }
      const rect = canvasRef.current.getBoundingClientRect();
      res.x = event.clientX - rect.left;
      res.y = event.clientY - rect.top;

      return res;
    },
    [canvasRef]
  );

  const clearCanvas = useCallback(
    (type: PaintType = 'pencil') => {
      roomSocket.emit('BE-clear-canvas', {
        receiveUserId,
        data: { type },
      });
      const ref = canvasRef.current;
      ref?.getContext('2d')?.clearRect(0, 0, ref.width, ref.height);
    },
    [canvasRef, roomSocket, receiveUserId]
  );

  const startPaint = useCallback(
    (event: MouseEvent) => {
      if (!paintType) return null;

      if (clearTimeoutId) {
        window.clearTimeout(clearTimeoutId);
        setClearTimeoutId(null);
      }

      const coordinates = getCoordinates(event);

      if (coordinates) {
        setMousePosition(coordinates);
        setIsPainting(true);
        const data: OnStartPaintData = {
          height: Number(canvasRef.current?.height),
          width: Number(canvasRef.current?.width),
        };
        roomSocket.emit('BE-paint-line-start', {
          receiveUserId,
          data,
        });
      }
    },
    [
      canvasRef,
      clearTimeoutId,
      getCoordinates,
      paintType,
      roomSocket,
      receiveUserId,
    ]
  );

  const endPaint = useCallback(() => {
    setClearTimeoutId(setTimeout(() => clearCanvas(), 1000 * clearInterval));
    setIsPainting(false);
    setMousePosition(undefined);
  }, [clearCanvas]);

  const sendToReceiver = useCallback(
    (mousePosition: any, newMousePosition: any) => {
      roomSocket.emit('BE-paint-line', {
        receiveUserId,
        data: {
          width: canvasRef.current?.width,
          height: canvasRef.current?.height,
          mousePosition,
          newMousePosition,
          type: paintType,
        },
      });
    },
    [canvasRef, roomSocket, receiveUserId, paintType]
  );

  const paint = useCallback(
    (event: MouseEvent) => {
      if (isPainting) {
        const newMousePosition = getCoordinates(event);
        if (mousePosition && newMousePosition) {
          drawLine({
            canvasRef,
            originalMousePosition: mousePosition,
            newMousePosition,
          });
          setMousePosition(newMousePosition);
          sendToReceiver(mousePosition, newMousePosition);
        }
      }
    },
    [canvasRef, getCoordinates, isPainting, mousePosition, sendToReceiver]
  );

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    canvas.addEventListener('mousedown', startPaint);
    return () => {
      canvas.removeEventListener('mousedown', startPaint);
    };
  }, [canvasRef, startPaint]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    canvas.addEventListener('mousemove', paint);
    return () => {
      canvas.removeEventListener('mousemove', paint);
    };
  }, [canvasRef, paint]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    canvas.addEventListener('mouseup', endPaint);
    canvas.addEventListener('mouseleave', endPaint);
    return () => {
      canvas.removeEventListener('mouseup', endPaint);
      canvas.removeEventListener('mouseleave', endPaint);
    };
  }, [canvasRef, endPaint]);

  return {
    clearCanvas,
  };
};

export default useSendPaint;

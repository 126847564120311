import { useCallback, useEffect, useState, FC } from 'react';
import { JitsiLocalTrack } from '../../../../types/Jitsi';

const Video: FC<{ track: JitsiLocalTrack | undefined | null; style: any }> = ({
  track,
  style,
}) => {
  const [element, setElement] = useState();

  const handleElement = useCallback((node) => {
    setElement(node);
  }, []);

  useEffect(() => {
    if (track && element) track.attach(element);

    return () => {
      if (track && element) track.detach(element);
    };
  }, [track, element]);

  return <video playsInline autoPlay ref={handleElement} style={style} />;
};

export default Video;

import { JitsiConference } from '../../types/Jitsi';
import { logTrackInfo } from '../../utils/debug';
import {
  addNewCameraTrack,
  addNewMicroTrack,
  addNewScreenTrack,
  getLocalCameraTrack,
  getLocalScreenTrack,
} from './helpers';

export const toggleCamera = async (jitsiRoom?: JitsiConference) => {
  if (!jitsiRoom) return;

  const track = getLocalCameraTrack(jitsiRoom);
  logTrackInfo('toggle_camera', track);

  if (!track) return addNewCameraTrack(jitsiRoom, '');

  return track.isMuted() ? track.unmute() : track.mute();
};

export const toggleMicro = (jitsiRoom?: JitsiConference) => {
  if (!jitsiRoom) return;

  const track = jitsiRoom?.getLocalAudioTrack();
  logTrackInfo('toggle_micro', track);

  if (!track) return addNewMicroTrack(jitsiRoom, '');

  return track.isMuted() ? track.unmute() : track.mute();
};

export const toggleScreenSharing = (
  jitsiRoom: JitsiConference | undefined,
  options: any
) => {
  if (!jitsiRoom) return;

  const track = getLocalScreenTrack(jitsiRoom);
  logTrackInfo('toggle_screen');

  return track ? track.dispose() : addNewScreenTrack(jitsiRoom, options);
};

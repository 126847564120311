import { useCallback, useEffect, useMemo, useRef, useState, VFC } from 'react';
import { useSelector } from 'react-redux';
import {
  VideocamOff as VideocamOffIcon,
  MicOff as MicOffIcon,
} from '@mui/icons-material';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import Paint from './Paint';
import MockUserCard from '../MockUserCard';
import {
  Root,
  PaintLayer,
  Video,
  BottomLayer,
  UserMeetName,
  VideoWrap,
  UserMeetNameSpan,
  VideoContainer,
  LeftBottom,
  ReportWrap,
} from './styled';

import { VideoCardProps } from './interfaces';
import Controls from './Controls';
import { RootState } from '../../../../store';
import { RATIO } from './constants';
import Tooltip from '../../../UI/Tooltip';
import ConnectionStats from '../ConntectionStats';
import { PaintType } from '../../../../hooks/paint/interfaces';
import { useTranslation } from 'react-i18next';
import ZoomControls from './ZoomControls';

const VideoCard: VFC<VideoCardProps> = ({
  toggleMainVideo,
  isSelf,
  client,
  stats,
  localStats,
}) => {
  const {
    userId,
    isScreenSharing,
    userMeetName,
    isElectron,
    jitsiUserId,
    tracks: {
      videoTrack,
      hasVideoAccess,
      isVideoEnabled,
      isMicroEnabled,
      hasMicroAccess,
    },
  } = client;
  const { t } = useTranslation();
  const [cardSize, setCardSize] = useState({ width: 0, height: 0 });
  const [paintType, setPaintType] = useState<PaintType>('');
  const [clearBrush, setClearBrush] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [videoSize, setVideoSize] = useState({ width: 0, height: 0 });

  const mirrorCameras = useSelector(
    (state: RootState) => state.room.mirrorCameras
  );

  const [videoElement, setVideoElement] = useState<
    HTMLVideoElement | undefined
  >();
  const handleVideoElement = useCallback((node) => {
    setVideoElement(node);
  }, []);

  const [paintElement, setPainElement] = useState<HTMLElement | undefined>();
  const handlePainElement = useCallback((node) => {
    setPainElement(node);
  }, []);

  const rootRef = useRef<any>({});
  const videoWrapRef = useRef<any>({});

  const resizeObserver = useMemo(
    () =>
      new ResizeObserver((entries) => {
        const {
          contentRect: { width, height },
        } = entries[0];
        setCardSize({ width, height });
      }),
    []
  );

  const videoHeight = videoElement?.videoHeight || 0;
  const videoWidth = videoElement?.videoWidth || 0;
  const videoElementHeight = videoElement?.clientHeight || 0;
  const videoElementWidth = videoElement?.clientWidth || 0;

  useEffect(() => {
    const sHeight = videoElementHeight / videoHeight;
    const sWidth = videoElementWidth / videoWidth;

    if (!paintElement) return;

    const offset = { x: 0, y: 0 };
    if (sWidth < sHeight) {
      offset.y = (videoElementHeight - videoHeight * sWidth) / 2;
      setVideoSize({
        width: videoElementWidth,
        height: videoHeight * sWidth,
      });
    } else {
      offset.x = (videoElementWidth - videoWidth * sHeight) / 2;
      setVideoSize({
        width: videoWidth * sHeight,
        height: videoElementHeight,
      });
    }

    const style = paintElement.style;
    style.left = style.right = `${offset.x}px`;
    style.top = style.bottom = `${offset.y}px`;
  }, [
    videoElementWidth,
    videoElementHeight,
    videoWidth,
    videoHeight,
    paintElement,
  ]);

  const toggleFullScreen = useCallback(() => {
    setIsFullScreen((prevFullScreen) => {
      if (prevFullScreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen(); //@ts-ignore
        } else if (document.webkitExitFullscreen) {
          /* Safari */ //@ts-ignore
          document.webkitExitFullscreen(); //@ts-ignore
        } else if (document.msExitFullscreen) {
          /* IE11 */ //@ts-ignore
          document.msExitFullscreen();
        }
      } else {
        if (rootRef.current.requestFullscreen) {
          rootRef.current.requestFullscreen();
        } else if (rootRef.current.webkitRequestFullscreen) {
          /* Safari */
          rootRef.current.webkitRequestFullscreen();
        } else if (rootRef.current.msRequestFullscreen) {
          /* IE11 */
          rootRef.current.msRequestFullscreen();
        }
      }
      return !prevFullScreen;
    });
  }, []);

  useEffect(() => {
    if (rootRef.current) {
      resizeObserver.observe(rootRef.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, [resizeObserver]);

  useEffect(() => {
    if (videoElement && videoTrack) videoTrack.attach(videoElement);

    return () => {
      if (videoElement && videoTrack) videoTrack.detach(videoElement);
    };
  }, [videoTrack, videoElement]);

  useEffect(() => {
    document.onfullscreenchange = function () {
      setIsFullScreen(Boolean(this.fullscreenElement));
    };
  }, [isFullScreen]);

  useEffect(() => {
    if (!videoWrapRef.current || !videoWrapRef.current.style) {
      return;
    }
    const { height, width } = cardSize;
    const { x, y } = RATIO;
    let newHeight = height;
    let newWidth = width;

    newHeight = (y * width) / x;

    if (Math.round(newHeight) >= Math.round(height)) {
      newWidth = (x * height) / y;
    }

    videoWrapRef.current.style.maxHeight = `${newHeight}px`;
    videoWrapRef.current.style.maxWidth = `${newWidth}px`;
  }, [cardSize]);

  const showMediaStream = isVideoEnabled || isScreenSharing;

  const microIconColor = hasMicroAccess ? 'yellow' : 'red';
  const videoIconColor = hasVideoAccess ? 'yellow' : 'red';

  return (
    <Root ref={rootRef}>
      <VideoWrap ref={videoWrapRef}>
        <VideoContainer>
          {showMediaStream && (
            <TransformWrapper>
              <ZoomControls />
              <TransformComponent
                wrapperStyle={{ width: '100%', height: '100%' }}
                contentStyle={{ width: '100%', height: '100%' }}
              >
                <Video
                  playsInline
                  autoPlay
                  ref={handleVideoElement}
                  muted={isSelf}
                  mirror={mirrorCameras && !isScreenSharing}
                />
              </TransformComponent>
            </TransformWrapper>
          )}
          {paintType && (
            <PaintLayer ref={handlePainElement}>
              <Paint
                size={videoSize}
                paintType={paintType}
                receiveUserId={userId}
                clear={clearBrush}
              />
            </PaintLayer>
          )}
        </VideoContainer>

        <ConnectionStats
          userId={jitsiUserId}
          stats={stats}
          localStats={localStats}
        />
        {!showMediaStream && <MockUserCard userMeetName={userMeetName} />}

        <BottomLayer>
          <LeftBottom>
            <UserMeetName>
              <UserMeetNameSpan>
                {isSelf ? t('you') : userMeetName}
              </UserMeetNameSpan>
            </UserMeetName>
            {!(hasVideoAccess && isVideoEnabled) && !isScreenSharing && (
              <ReportWrap>
                <Tooltip title={t('camera_forbidden')}>
                  <VideocamOffIcon
                    htmlColor={videoIconColor}
                    style={{
                      border: `2px solid ${videoIconColor}`,
                      borderRadius: 4,
                    }}
                  />
                </Tooltip>
              </ReportWrap>
            )}
            {!(hasMicroAccess && isMicroEnabled) && !isScreenSharing && (
              <ReportWrap>
                <Tooltip title={t('micro_forbidden')}>
                  <MicOffIcon
                    htmlColor={microIconColor}
                    style={{
                      border: `2px solid ${microIconColor}`,
                      borderRadius: 4,
                    }}
                  />
                </Tooltip>
              </ReportWrap>
            )}
          </LeftBottom>
          {!isSelf && (
            <Controls
              toggleMainVideo={toggleMainVideo}
              setPaintType={setPaintType}
              toggleFullScreen={toggleFullScreen}
              clearPainting={() => setClearBrush((prev) => prev + 1)}
              paintType={paintType}
              canPaint={isScreenSharing && isElectron}
            />
          )}
        </BottomLayer>
      </VideoWrap>
    </Root>
  );
};

VideoCard.defaultProps = {
  isSelf: false,
};

export default VideoCard;

import { Drawer } from '@mui/material';
import { useEffect, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Form from './Form';
import Message from './Message';
import { MessagesSection, FormSection, Root } from './styled';
import { roomSocket } from '../../../../socket';
import { RootState } from '../../../../store';
import {
  ChatMessage,
  setAllMessagesRead,
  toggleOpenChat,
  updateChatMessages,
} from '../../../../store/room';

const Chat: VFC = () => {
  const {
    room: {
      chat: { isOpen, messages },
    },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    roomSocket.on('FE-chat-update', (data: Omit<ChatMessage, 'isRead'>[]) => {
      dispatch<any>(updateChatMessages(data));
    });
  }, [dispatch]);

  useEffect(() => {
    if (isOpen) {
      dispatch(setAllMessagesRead());
    }
  }, [dispatch, isOpen]);

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={() => dispatch(toggleOpenChat())}
    >
      <Root>
        <MessagesSection>
          {messages.map(({ id, user: { userMeetName }, date, text }) => (
            <Message userName={userMeetName} date={date} text={text} key={id} />
          ))}
        </MessagesSection>
        <FormSection>
          <Form />
        </FormSection>
      </Root>
    </Drawer>
  );
};

export default Chat;

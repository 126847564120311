import styled from 'styled-components';
import { Button } from '@mui/material';

export const Root = styled.div`
  width: 100%;
  box-sizing: border-box;
  background-color: limegreen;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 10px 40px;
  display: flex;
`;

export const StyledBtn = styled(Button)`
  color: white;
`;

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import './polyfills';
import { store } from './store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import './index.css';

Bugsnag.start({
  apiKey: '1bcf5df7e2b94ae3524d67259d5c498f',
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['production'],
});
const ErrorBoundary =
  Bugsnag.getPlugin('react')?.createErrorBoundary(React) || React.Fragment;

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <App />
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.unregister();

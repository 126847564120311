import { Button } from '@mui/material';
import Jitsi from 'lib-jitsi-meet';

const TestSoundButton = () => {
  const onTestSound = () => {
    const audio: any = new Audio('/sounds/ring.mp3');
    audio.setSinkId(Jitsi.mediaDevices.getAudioOutputDevice());
    audio.play();
  };
  return (
    <Button variant="contained" color="primary" onClick={() => onTestSound()}>
      Test
    </Button>
  );
};

export default TestSoundButton;

import { useMemo, useState, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import StopScreenShareIcon from '@mui/icons-material/StopScreenShare';
import CallEndIcon from '@mui/icons-material/CallEnd';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChatIcon from '@mui/icons-material/Chat';
import Badge from '@mui/material/Badge';

import SettingsModal from '../../../SettingsModal';
import { Root } from './styled';
import { ButtonsPanelProps } from './interfaces';
import { RootState } from '../../../../store';
import ControlButton from '../../../UI/ControlButton';
import { RED_BUTTON, WHITE_BUTTON } from '../../../UI/ControlButton/constants';
import { ChatMessage, toggleOpenChat } from '../../../../store/room';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  controlButton: {
    margin: '5px',
  },
  autoMarginButton: {
    margin: '5px',
    marginLeft: 'auto',
  },
}));

const ButtonsPanel: VFC<ButtonsPanelProps> = ({
  animateButton,
  isScreenSharing,
  toggleScreenSharing,
  toggleAudio,
  isAudioEnabled,
  isVideoEnabled,
  toggleCamera,
  leaveRoom,
  onButtonContextMenu,
}) => {
  const { t } = useTranslation();
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const classes = useStyles();

  const {
    room: {
      chat: { messages },
    },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  const notReadChatMessages = useMemo(
    () =>
      messages.reduce((acc: ChatMessage[], value: ChatMessage) => {
        if (!value.isRead) {
          acc.push(value);
        }
        return acc;
      }, []),
    [messages]
  );

  return (
    <Root>
      {showSettingsModal && (
        <SettingsModal
          open={true}
          onClose={() => setShowSettingsModal(false)}
        />
      )}

      <ControlButton
        animate={animateButton === 'screen'}
        className={classes.autoMarginButton}
        onClick={toggleScreenSharing}
        onContextMenu={(e) => onButtonContextMenu('screen', e)}
        enabledIcon={StopScreenShareIcon}
        disabledIcon={ScreenShareIcon}
        enabledColors={RED_BUTTON}
        disabledColors={WHITE_BUTTON}
        enabled={isScreenSharing}
        tooltip={isScreenSharing ? t('screen_turn_off') : t('screen_turn_on')}
      />

      <ControlButton
        animate={animateButton === 'micro'}
        className={classes.controlButton}
        onClick={toggleAudio}
        onContextMenu={(e) => onButtonContextMenu('micro', e)}
        enabledIcon={MicIcon}
        disabledIcon={MicOffIcon}
        enabledColors={WHITE_BUTTON}
        disabledColors={RED_BUTTON}
        enabled={isAudioEnabled}
        tooltip={isAudioEnabled ? t('micro_turn_off') : t('micro_turn_on')}
      />

      <ControlButton
        animate={animateButton === 'camera'}
        className={classes.controlButton}
        onClick={toggleCamera}
        onContextMenu={(e) => onButtonContextMenu('camera', e)}
        enabledIcon={VideocamIcon}
        disabledIcon={VideocamOffIcon}
        enabledColors={WHITE_BUTTON}
        disabledColors={RED_BUTTON}
        enabled={isVideoEnabled}
        tooltip={isVideoEnabled ? t('camera_turn_off') : t('camera_turn_on')}
      />

      <ControlButton
        animate={animateButton === 'settings'}
        className={classes.controlButton}
        onClick={() => setShowSettingsModal(true)}
        onContextMenu={(e) => onButtonContextMenu('settings', e)}
        enabledIcon={MoreVertIcon}
        enabledColors={WHITE_BUTTON}
        tooltip={t('settings')}
      />

      <ControlButton
        id="btn-leave"
        animate={animateButton === 'leave'}
        className={classes.controlButton}
        onClick={leaveRoom}
        onContextMenu={(e) => onButtonContextMenu('leave', e)}
        enabledIcon={CallEndIcon}
        enabledColors={RED_BUTTON}
        tooltip={t('leave')}
      />

      <ControlButton
        className={classes.autoMarginButton}
        animate={animateButton === 'chat'}
        onClick={() => dispatch(toggleOpenChat())}
        onContextMenu={(e) => onButtonContextMenu('chat', e)}
        enabledIcon={(props) => {
          return (
            <Badge badgeContent={notReadChatMessages.length} color="primary">
              <ChatIcon {...props} />
            </Badge>
          );
        }}
        enabledColors={WHITE_BUTTON}
        tooltip={t('chat')}
      />
    </Root>
  );
};

export default ButtonsPanel;

import { User } from '../types/user';
import axios from './index';
import { progkidsAPI } from './progkids';

async function isUserLoggedIn(): Promise<User | null> {
  const { data } = await axios.post<User | null>(`/api/user/loggedin`);

  return data || (await progkidsAPI.isUserLoggedIn());
}

async function login(params: {
  username: string;
  password: string;
}): Promise<User | null> {
  const { data } = await axios.post<User | null>(`/api/user/login`, params);

  return data;
}

async function logout(): Promise<void> {
  await axios.get(`/api/user/logout`);
}

async function setRegion(meetRegion: string): Promise<void> {
  await axios.post(`/api/user/region`, { meetRegion });
}

export const userAPI = {
  isUserLoggedIn,
  login,
  logout,
  setRegion,
};

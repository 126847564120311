import { roomSocket } from '../socket';
import { JitsiLocalTrack } from '../types/Jitsi';

export const debug = (...args: any) => {
  const isDebug = true; // Boolean(localStorage.getItem('debug') === '*');
  if (isDebug) {
    console.log(...args);
  }
};

interface LogOptions {
  event: string;
  userId?: string;
  userName?: string;
  roomId?: string;
  data?: any;
}

let userId = '';
let roomId = '';
let userName = '';

export const setUser = (id: string, name: string) => {
  userId = id;
  userName = name;
};
export const setRoomId = (id: string) => (roomId = id);

export const logEvent = (options: LogOptions) => {
  options.userId = userId;
  options.roomId = roomId;
  options.userName = userName;

  roomSocket.emit('BE-send-command', { command: 'logevent', options });
};

export const logTrackInfo = (event: string, track?: JitsiLocalTrack) => {
  logEvent({
    event,
    data: {
      track: {
        exists: !!track,
        mutedNow: track?.isMuted(),
        mutedNext: !(track && track.isMuted()),
        disposed: track?.disposed,
        ended: track?.isEnded(),
        deviceId: track?.getDeviceId(),
        sourceName: track?.getSourceName(),
      },
    },
  });
};

import moment from 'moment';

export const getSrcLink = (
  node: HTMLVideoElement | HTMLAudioElement
): string => {
  // @ts-ignore
  const { src } = node.firstChild || {};

  return String(src).split('?')[0];
};

export const getTimeFormSrc = (src: string) => {
  const lastDotIndex = src.lastIndexOf('.');
  const timeString = src.substring(lastDotIndex - 17, lastDotIndex);
  return moment.utc(timeString, 'DD.MM.YY-HH.mm.ss');
};

export const unifyDialog = (json: any, type: string) => {
  const messages: {
    start: number;
    end: number;
    text: string;
    speaker: string;
  }[] = [];

  Object.keys(json).forEach((key) => {
    const userData = json[key];
    const speaker = key;

    switch (type) {
      case 'whisper_free':
        ((userData as any).segments || []).forEach((m: any) => {
          messages.push({
            start: m[0],
            end: m[1],
            text: m[2],
            speaker,
          });
        });
        break;
      case 'whisper_paid':
        ((userData as any).segments || []).forEach((m: any) => {
          messages.push({
            start: m.start,
            end: m.end,
            text: m.text,
            speaker,
          });
        });
        break;
      case 'nanosemantic_paid':
      case 'nanosemantic_audio':
        ((userData as any).phrases || []).forEach((m: any) => {
          messages.push({
            start: m.start,
            end: m.end,
            text: m.phrase,
            speaker,
          });
        });
        break;
    }
  });

  return messages.sort((a, b) => a.start - b.start);
};

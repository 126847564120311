import styled from 'styled-components';

export const Root = styled.div`
  margin: 0 auto;
  width: fit-content;
  margin-top: 20px;
  text-align: center;
`;

export const InputWrap = styled.div`
  margin-bottom: 20px;
`;

export const Img = styled.img`
  display: block;
  margin-bottom: 20px;
`;

export const BtnsWrap = styled.div`
  display: grid;
  grid-gap: 10px;
`;

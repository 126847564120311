import styled from 'styled-components';

export const Root = styled.div`
  margin: 15px auto;
  display: block;
  box-sizing: border-box;
  color: black;
  padding: 5px;
  border-radius: 5px;
  background-color: white;
`;

export const UserName = styled.span`
  font-weight: bold;
  margin-right: 10px;
  font-size: 12px;
`;

export const Date = styled.span`
  color: #a8a8a8;
`;

export const Info = styled.div``;

export const Text = styled.div`
  font-size: 14px;
`;

import styled from 'styled-components';

export const Root = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #303030;
`;

export const FullScreenWrap = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-column-gap: 20px;
  height: 100%;
  margin: 20px;
`;

export const FullScreenVideos = styled.div`
  display: grid;
  grid-template-rows: repeat(auto-fit, minmax(100px, 150px));
  grid-gap: 20px;
`;

import styled from 'styled-components';

export const Root = styled.div`
  position: absolute;
  max-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  margin: 0 auto;
  color: white;
  text-align: center;
  background-color: #262525;
  width: 100%;
  height: 100%;
`;

export const AvatarWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const UserMeetName = styled.span`
  font-size: 100%;
`;

import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Jitsi from 'lib-jitsi-meet';

import {
  getUserMicroDevices,
  getUserCameraDevices,
  getUserAudioDevices,
} from '../../utils/navigator';
import { RootState } from '../../store';
import {
  setMicroDevices,
  setCameraDevices,
  setCurrentMicroDevice,
  setCurrentCameraDevice,
  setAudioDevices,
  setCurrentAudioDevice,
} from '../../store/auth';
import { debug } from '../../utils/debug';
import { useTranslation } from 'react-i18next';

const useDevices = () => {
  const { t } = useTranslation();
  const {
    auth: {
      devices: {
        camera: cameraDevices,
        micro: microDevices,
        audio: audioDevices,
        currentAudio,
        currentCamera,
        currentMicro,
      },
    },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  const errorMessage = useMemo(() => {
    if (!microDevices.length && !cameraDevices.length && !audioDevices.length) {
      return t('error_1');
    }

    if (!microDevices.length) {
      return t('error_2');
    }

    if (!cameraDevices.length) {
      return t('error_3');
    }
  }, [audioDevices.length, cameraDevices.length, microDevices.length, t]);

  const setCameraDevice = useCallback(
    (cameraDeviceId) => {
      dispatch(setCurrentCameraDevice(cameraDeviceId));
    },
    [dispatch]
  );

  const setMicroDevice = useCallback(
    (microDeviceId) => {
      dispatch(setCurrentMicroDevice(microDeviceId));
    },
    [dispatch]
  );

  const setAudioDevice = useCallback(
    (audioDeviceId) => {
      dispatch(setCurrentAudioDevice(audioDeviceId));
    },
    [dispatch]
  );

  useEffect(() => {
    debug('INIT DEVICES');

    const initDevices = async () => {
      const microDevices = await getUserMicroDevices();
      const cameraDevices = await getUserCameraDevices();
      const audioDevices = await getUserAudioDevices();

      dispatch(setMicroDevices(microDevices));
      dispatch(setCameraDevices(cameraDevices));
      dispatch(setAudioDevices(audioDevices));
    };

    const { DEVICE_LIST_CHANGED } = Jitsi.events.mediaDevices;
    initDevices();
    Jitsi.mediaDevices.addEventListener(DEVICE_LIST_CHANGED, initDevices);

    return () => {
      Jitsi.mediaDevices.removeEventListener(DEVICE_LIST_CHANGED, initDevices);
    };
  }, [dispatch]);

  return {
    cameraDevices,
    microDevices,
    audioDevices,
    currentAudio,
    currentCamera,
    currentMicro,
    errorMessage,
    setMicroDevice,
    setCameraDevice,
    setAudioDevice,
  };
};

export default useDevices;

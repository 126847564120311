import { FC, useEffect, useState } from 'react';
import { getMergedVideo, mergedVideo } from '../../api/meetRecords';
import { MeetRecord } from '../../api/models/meetRecords';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Checkbox, FormControlLabel } from '@mui/material';
import moment from 'moment';

interface Props {
  record: MeetRecord;
}

const MergedVideo: FC<Props> = ({ record }) => {
  const [link, setLink] = useState('');
  const [data, setData] = useState<any>({});
  const [disableCamera, setDisableCamera] = useState(false);
  const [disableScreen, setDisableScreen] = useState(false);

  useEffect(() => {
    if (!record._id) return;

    const loadInfo = () => {
      getMergedVideo(record._id).then((result) => {
        const { stats, recordUrl } = result || {};
        setLink(recordUrl);
        setData(stats || {});
      });
    };

    const interval = setInterval(loadInfo, 10000);
    loadInfo();

    return () => {
      clearInterval(interval);
    };
  }, [record._id]);

  const onMergeClick = () => {
    if (!record._id) return;

    mergedVideo(record._id, {
      disableCamera,
      disableScreen,
    }).then((data) => {
      alert('Record added into queue. To finish it can takes 15 minutes');
    });
  };

  const hasVideo = !!link;
  const { progress = {} } = data;

  const printDate = (value: number) => {
    if (!value) return '';
    return moment(value).fromNow();
  };
  return (
    <div>
      Видео собрано: {hasVideo ? '  ✅  ' : '  🚫  '} {data.error}
      {hasVideo && (
        <a href={link} target="_blank" rel="noreferrer">
          Download
        </a>
      )}
      {link && (
        <video className="controls" width="100%" preload="auto" controls={true}>
          <source src={link} type="video/mp4" />
        </video>
      )}
      <p>
        Параметры сборки:
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e, value) => setDisableCamera(!value)}
              checked={!disableCamera}
            />
          }
          label="Камера"
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e, value) => setDisableScreen(!value)}
              checked={!disableScreen}
            />
          }
          label="Экран"
        />
        <button onClick={onMergeClick}>Собрать</button>
      </p>
      <p>
        Процесс сборки: <b>{data.isInProgress ? '' : 'не'} собирается</b>
      </p>
      <Stepper alternativeLabel>
        <Step completed={!!progress.start}>
          <StepLabel>
            {printDate(progress.start)}
            <br />
            start
          </StepLabel>
        </Step>
        <Step completed={!!progress.loadAudioCompete}>
          <StepLabel>
            {printDate(progress.loadAudioCompete)}
            <br />
            loadAudio
          </StepLabel>
        </Step>
        <Step completed={!!progress.mergeAudioCompete}>
          <StepLabel>
            {printDate(progress.mergeAudioCompete)}
            <br />
            mergeAudio
          </StepLabel>
        </Step>
        <Step completed={!!progress.loadVideoCompete}>
          <StepLabel>
            {printDate(progress.loadVideoCompete)}
            <br />
            loadVideo
          </StepLabel>
        </Step>
        <Step completed={!!progress.mp4VideoCompete}>
          <StepLabel>
            {printDate(progress.mp4VideoCompete)}
            <br />
            mp4Video
          </StepLabel>
        </Step>
        <Step completed={!!progress.mergeVideoCompete}>
          <StepLabel>
            {printDate(progress.mergeVideoCompete)}
            <br />
            mergeVideo
          </StepLabel>
        </Step>
        <Step completed={!!progress.uploadComplete}>
          <StepLabel>
            {printDate(progress.uploadComplete)}
            <br />
            upload
          </StepLabel>
        </Step>
      </Stepper>
      <pre>{JSON.stringify(data, null, 4)}</pre>
    </div>
  );
};

export default MergedVideo;

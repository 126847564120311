import { FC, useEffect, useMemo, useState, VFC } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import VideoCard from './partials/VideoCard';
import ButtonsPanel from './partials/ButtonsPanel';
import Audio from './partials/Audio';
import { Root, FullScreenWrap, FullScreenVideos } from './styled';
import { RootState } from '../../store';
import { RoomProps } from './interfaces';
import Chat from './partials/Chat';
import { ClientsState } from '../../hooks/useRoom/interfaces';
import { roomSocket } from '../../socket';
import RegionContainer from '../RegionContainer';

const AudiosRender: FC<{ clients: ClientsState }> = ({ clients }) => (
  <>
    {Object.keys(clients).map((userId) => {
      const {
        tracks: { microTrack },
      } = clients[userId];
      if (userId === 'localUser') return null;

      return <Audio track={microTrack} key={userId} />;
    })}
  </>
);

const Room: VFC<RoomProps> = ({
  toggleCamera,
  toggleMicro,
  toggleScreenSharing,
  leaveRoom,
}) => {
  const [mainVideoJitsiUserId, setMainVideoJitsiUserId] = useState('');
  const [animateButton, setAnimateButton] = useState('');

  const {
    room: { clients, stats },
  } = useSelector((state: RootState) => state);

  const mainVideoModeCards = useMemo((): React.ReactNode[] => {
    if (!mainVideoJitsiUserId) return [];

    return Object.keys(clients)
      .filter((clientId) => clientId !== mainVideoJitsiUserId)
      .map((clientId) => (
        <VideoCard
          stats={stats[clientId]}
          localStats={stats['localUser']}
          isSelf={clientId === 'localUser'}
          key={clientId}
          toggleMainVideo={() => setMainVideoJitsiUserId(clientId)}
          client={clients[clientId]}
        />
      ));
  }, [clients, mainVideoJitsiUserId, stats]);

  useEffect(() => {
    if (!clients[mainVideoJitsiUserId]) {
      setMainVideoJitsiUserId('');
    }
  }, [clients, mainVideoJitsiUserId]);

  useEffect(() => {
    roomSocket.on('FE-send-command', (data) => {
      if (data && data.command === 'highlight') {
        setAnimateButton(data.type);
        setTimeout(() => setAnimateButton(''), 3000);
      }
    });
  }, []);

  return (
    <Root>
      <RegionContainer />
      {clients[mainVideoJitsiUserId] ? (
        <FullScreenWrap>
          <VideoCard
            stats={stats[mainVideoJitsiUserId]}
            localStats={stats['localUser']}
            toggleMainVideo={() => setMainVideoJitsiUserId('')}
            client={clients[mainVideoJitsiUserId]}
          />
          <FullScreenVideos>{mainVideoModeCards}</FullScreenVideos>
        </FullScreenWrap>
      ) : (
        <Grid
          container
          style={{ height: '100%', padding: '20px' }}
          justifyContent="center"
          spacing={2}
        >
          {Object.keys(clients).map((jitsiUserId) => (
            <Grid item xs={6} key={jitsiUserId}>
              <VideoCard
                stats={stats[jitsiUserId]}
                localStats={stats['localUser']}
                toggleMainVideo={() => setMainVideoJitsiUserId(jitsiUserId)}
                isSelf={jitsiUserId === 'localUser'}
                client={clients[jitsiUserId]}
              />
            </Grid>
          ))}
        </Grid>
      )}

      <ButtonsPanel
        animateButton={animateButton}
        isScreenSharing={!!clients.localUser_screen}
        isAudioEnabled={clients.localUser.tracks.isMicroEnabled}
        isVideoEnabled={clients.localUser.tracks.isVideoEnabled}
        toggleAudio={toggleMicro}
        toggleScreenSharing={toggleScreenSharing}
        toggleCamera={toggleCamera}
        leaveRoom={leaveRoom}
        onButtonContextMenu={(type, e) => {
          e.preventDefault();
          roomSocket.emit('BE-send-command', { command: 'highlight', type });
        }}
      />

      <AudiosRender clients={clients} />

      <Chat />
    </Root>
  );
};

export default Room;

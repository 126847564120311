import { VFC } from 'react';
import moment from 'moment';

import { Root } from './styled';
import { MessageProps } from './interfaces';
import { Info, UserName, Date } from './styled';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const Message: VFC<MessageProps> = ({ userName, date, text }) => {
  return (
    <Root>
      <Info>
        <UserName>{userName}</UserName>
        <Date>{moment(date).format('HH:mm')}</Date>
      </Info>
      <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
        {text}
      </ReactMarkdown>
    </Root>
  );
};

export default Message;

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniqueNamesGenerator, starWars, colors } from 'unique-names-generator';

import { RootState } from '.';
import { ClientsState, Tracks } from '../hooks/useRoom/interfaces';
import { showChatMessageToast } from '../utils/toasts';
import { JitsiStats } from '../types/Jitsi';

export interface ChatMessage {
  id: string;
  user: {
    jitsiUserId: string;
    userMeetName: string;
  };
  date: string;
  text: string;
  isRead: boolean;
}

export interface RoomState {
  roomId: string;
  mirrorCameras: boolean;
  noiseSuppression: boolean;
  senderVideoQuality: number;
  clients: ClientsState;
  stats: {
    [key: string]: JitsiStats;
  };
  chat: {
    isOpen: boolean;
    messages: ChatMessage[];
  };
}

const localUser = {
  userId: '',
  jitsiUserId: '',
  tracks: new Tracks(null, null),
  isScreenSharing: false,
  userMeetName: uniqueNamesGenerator({
    dictionaries: [colors, starWars],
    length: 2,
    separator: ' ',
  }),
  isElectron: navigator.userAgent.includes('Electro'),
};

const initialState: RoomState = {
  roomId: '',
  mirrorCameras: true,
  noiseSuppression: true,
  senderVideoQuality: 1080,
  chat: {
    isOpen: false,
    messages: [],
  },
  clients: {
    localUser,
  },
  stats: {},
};

const updateChatMessages = createAsyncThunk(
  'room/updateChatMessages',
  async (messages: Omit<ChatMessage, 'isRead'>[], { dispatch, getState }) => {
    // @ts-ignore
    const {
      room: {
        chat: { messages: prevMessages, isOpen },
        clients: {
          localUser: { jitsiUserId },
        },
      },
    }: RootState = getState();

    messages.forEach((message) => {
      const isNewMessage = !Boolean(
        prevMessages.find((item) => item.id === message.id)
      );
      if (isNewMessage) {
        const isRead =
          message.user.jitsiUserId === jitsiUserId || isOpen ? true : false;
        if (!isRead) {
          showChatMessageToast({
            userMeetName: message.user.userMeetName,
            message: message.text,
            onClick: () => dispatch(toggleOpenChat()),
          });
        }
        dispatch(
          addChatMessage({
            ...message,
            isRead,
          })
        );
      }
    });
  }
);

export const roomSlice = createSlice({
  name: 'room',
  initialState,
  reducers: {
    setStats: (
      state,
      action: PayloadAction<{ id: string; stats: JitsiStats }>
    ) => {
      state.stats[action.payload.id] = { ...action.payload.stats };
    },
    setClients: (
      state,
      action: PayloadAction<(prev: ClientsState) => ClientsState>
    ) => {
      state.clients = action.payload(state.clients as any);
    },
    setUserMeetName: (state, action: PayloadAction<string>) => {
      state.clients.localUser.userMeetName = action.payload;
    },
    toggleOpenChat: (state) => {
      state.chat.isOpen = !state.chat.isOpen;
    },
    addChatMessage: (state, action: PayloadAction<ChatMessage>) => {
      state.chat.messages.push(action.payload);
    },
    setAllMessagesRead: (state) => {
      state.chat.messages.forEach((message) => (message.isRead = true));
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.clients.localUser.userId = action.payload;
    },
    setJitsiUserId: (state, action: PayloadAction<string>) => {
      state.clients.localUser.jitsiUserId = action.payload;
    },
    setRoomId: (state, action: PayloadAction<string>) => {
      state.roomId = action.payload;
    },
    setMirrorCameras: (state, action: PayloadAction<boolean>) => {
      state.mirrorCameras = action.payload;
    },
    setNoiseSuppression: (state, action: PayloadAction<boolean>) => {
      state.noiseSuppression = action.payload;
    },
    setSenderVideoQuality: (state, action: PayloadAction<number>) => {
      state.senderVideoQuality = action.payload;
    },
  },
});

export const {
  setClients,
  setUserMeetName,
  toggleOpenChat,
  addChatMessage,
  setAllMessagesRead,
  setUserId,
  setJitsiUserId,
  setRoomId,
  setMirrorCameras,
  setNoiseSuppression,
  setSenderVideoQuality,
  setStats,
} = roomSlice.actions;

export { updateChatMessages };

export default roomSlice.reducer;

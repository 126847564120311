import { toast, ToastOptions } from 'react-toastify';
import { Message } from '@mui/icons-material';

const defaultOptions: ToastOptions = {
  pauseOnFocusLoss: false,
  hideProgressBar: true,
  theme: 'dark',
};

export const showInfoToast = (info: string, options?: ToastOptions) => {
  toast.info(info, { ...defaultOptions, ...options });
};

export const showErrorToast = (info: string) => {
  toast.error(info, defaultOptions);
};

export const showChatMessageToast = (payload: {
  userMeetName: string;
  message: string;
  onClick: () => void;
}) => {
  const { userMeetName, message, onClick } = payload;

  toast.info(
    <div>
      <h5>{userMeetName}</h5> {message}
    </div>,
    {
      pauseOnFocusLoss: false,
      hideProgressBar: true,
      theme: 'dark',
      position: 'bottom-right',
      style: { marginBottom: 100 },
      icon: <Message />,
      onClick,
    }
  );
};

import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';

import { receiveAuth } from './store/auth';
import SettingsBeforeConnection from './components/SettingsBeforeConnection';
import Home from './components/Home';
import Login from './components/Login';
import Records from './components/Records';
import Record from './components/Record';

import ClientPaintCanvas from './components/ReceivingPaintCanvas';

import 'react-chat-elements/dist/main.css';
import 'react-toastify/dist/ReactToastify.css';
import 'plyr-react/plyr.css';
import { logEvent } from './utils/debug';
import Recorder from './components/Recorder';
import Viewer from './components/Viewer';
import TestPage from './components/TestPage';
import './i18n/config';
import version from './version';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch<any>(receiveAuth());

    const onPageClose = () => {
      logEvent({ event: 'close_page' });
    };

    window.addEventListener('beforeunload', onPageClose);

    return () => {
      window.removeEventListener('beforeunload', onPageClose);
    };
  }, [dispatch]);

  return (
    <Router>
      <ToastContainer />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/test" exact component={TestPage} />
        <Route path="/record/:roomId" component={Recorder} />
        <Route path="/view/:roomId" component={Viewer} />
        <Route path="/room/:roomId" component={SettingsBeforeConnection} />
        <Route path="/paint-for/:userId" component={ClientPaintCanvas} />
        <Route path="/login" component={Login} />
        <Route path="/records" component={Records} exact />
        <Route path="/records/:recordId" component={Record} exact />
        <Redirect to="/" />
      </Switch>

      <i
        style={{
          fontSize: 10,
          opacity: 0.5,
          position: 'absolute',
          bottom: 0,
          left: 0,
        }}
      >
        V {version}
      </i>
    </Router>
  );
};

export default App;

import { useCallback, useEffect, useMemo } from 'react';

import { roomSocket } from '../../socket';
import { UseReceivePaintProps } from './interfaces';
import { drawLine } from './utils';

export const useReceivePaint = ({
  height,
  width,
  pencilRef,
  brushRef,
  userId,
}: UseReceivePaintProps) => {
  const scale = useMemo(() => ({ x: 0, y: 0 }), []);

  const clearCanvas = (data: any) => {
    const refs = [pencilRef.current];
    if (data?.data?.type === 'brush') {
      refs.push(brushRef.current);
    }

    refs.forEach((ref) =>
      ref?.getContext('2d')?.clearRect(0, 0, ref.width, ref.height)
    );
  };

  const onStart = useCallback(
    ({ data }) => {
      scale.x = width / data.width;
      scale.y = height / data.height;
    },
    [height, scale, width]
  );

  const onPaint = ({
    data: { newMousePosition, mousePosition, type },
  }: any) => {
    const isPencil = type === 'pencil';
    drawLine({
      color: isPencil ? 'red' : 'blue',
      canvasRef: isPencil ? pencilRef : brushRef,
      newMousePosition,
      originalMousePosition: mousePosition,
      scale,
    });
  };

  roomSocket.on('FE-paint-line-start', onStart);

  roomSocket.on('FE-paint-line', onPaint);

  roomSocket.on('FE-clear-canvas', clearCanvas);

  useEffect(() => {
    roomSocket.io.opts.query = {
      userMeetName: userId,
      userId,
      jitsiUserId: 'PROGKIDS_PAINT_CLIENT',
    };
    roomSocket.connect();
  }, [userId]);
};

export default useReceivePaint;

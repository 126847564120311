const getUserDevices = async (kind: MediaDeviceKind) => {
  const devices = await navigator.mediaDevices.enumerateDevices();
  return devices.filter((device) => device.kind === kind && device.label);
};

export const getUserCameraDevices = async () =>
  await getUserDevices('videoinput');

export const getUserMicroDevices = async () =>
  await getUserDevices('audioinput');

export const getUserAudioDevices = async () => getUserDevices('audiooutput');

import axios from './index';

interface GetSignedUrlOptions {
  name: string;
  type: string;
}

export const getSignedUrl = async (options: GetSignedUrlOptions) => {
  const { data } = await axios.post('/api/records/uploads/signed-url', options);

  return data;
};

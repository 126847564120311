import { FC } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  ModalProps,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

import useDevices from '../../hooks/useDevices';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import {
  setMirrorCameras,
  setNoiseSuppression,
  setSenderVideoQuality,
} from '../../store/room';
import TestSoundButton from '../SettingsBeforeConnection/TestSoundButton';
import { FormItem } from './styled';
import { useTranslation } from 'react-i18next';

const Modal: FC<Omit<ModalProps, 'children'>> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const {
    cameraDevices,
    microDevices,
    audioDevices,
    currentAudio,
    currentCamera,
    currentMicro,
    errorMessage,
    setMicroDevice,
    setCameraDevice,
    setAudioDevice,
  } = useDevices();

  const handleMicro = (e: any) => setMicroDevice(e.target.value);
  const handleCamera = (e: any) => setCameraDevice(e.target.value);
  const handleAudio = (e: any) => setAudioDevice(e.target.value);

  const dispatch = useDispatch();
  const { mirrorCameras, noiseSuppression, senderVideoQuality } = useSelector(
    (state: RootState) => state.room
  );

  const onMirrorCameraChange = (e: any) => {
    dispatch(setMirrorCameras(e.target.checked));
  };

  const onNoiseSuppressionChange = (e: any) => {
    dispatch(setNoiseSuppression(e.target.checked));
  };

  const onSenderVideoQualityChange = (e: any) => {
    dispatch(setSenderVideoQuality(e.target.value));
  };

  const hasDefaultMicro = microDevices.find((x) => x.deviceId === 'default');
  const hasDefaultCamera = cameraDevices.find((x) => x.deviceId === 'default');

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="settings-modal">
      <DialogTitle id="settings-modal">{t('settings')}</DialogTitle>

      <DialogContent>
        <span>{errorMessage}</span>
        {Boolean(microDevices.length) && (
          <FormItem>
            <InputLabel id="micro-select">{t('micro')}</InputLabel>
            <Select
              labelId="micro-select"
              value={currentMicro}
              onChange={handleMicro}
            >
              {!hasDefaultMicro && (
                <MenuItem value="default">{t('default')}</MenuItem>
              )}
              {microDevices.map(({ deviceId, label }: any) => (
                <MenuItem value={deviceId} key={deviceId}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormItem>
        )}

        {Boolean(cameraDevices.length) && (
          <FormItem>
            <InputLabel id="camera-select">{t('camera')}</InputLabel>
            <Select
              labelId="camera-select"
              value={currentCamera}
              onChange={handleCamera}
            >
              {!hasDefaultCamera && (
                <MenuItem value="default">{t('default')}</MenuItem>
              )}
              {cameraDevices.map(({ deviceId, label }) => (
                <MenuItem value={deviceId} key={deviceId}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormItem>
        )}

        {Boolean(audioDevices.length) && (
          <FormItem>
            <InputLabel id="demo-simple-select-helper-label">
              {t('speakers')} <TestSoundButton />
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              value={currentAudio}
              onChange={handleAudio}
            >
              {audioDevices.map(({ deviceId, label }) => (
                <MenuItem value={deviceId} key={deviceId}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormItem>
        )}
        <FormItem>
          <FormControlLabel
            label={t('camera_mirror')}
            checked={mirrorCameras}
            onChange={onMirrorCameraChange}
            control={<Checkbox />}
          />
        </FormItem>
        <FormItem>
          <FormControlLabel
            label={t('noise_suppression')}
            checked={noiseSuppression}
            onChange={onNoiseSuppressionChange}
            control={<Checkbox />}
          />
        </FormItem>
        <FormItem>
          <InputLabel>{t('screen_video_quality')}</InputLabel>
          <Select
            value={senderVideoQuality}
            onChange={onSenderVideoQualityChange}
          >
            <MenuItem value={2160}>ULTRA</MenuItem>
            <MenuItem value={1080}>HIGH</MenuItem>
            <MenuItem value={720}>MEDIUM</MenuItem>
            <MenuItem value={360}>LOW</MenuItem>
          </Select>
        </FormItem>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;

export const DATE_FORMAT = 'DD.MM.YY HH:mm';

export const STATUSES = {
  RECORDING: 'Запись',
  UPLOADING_PARTIALS: 'Загрузка на сервер',
  UPLOADED_PARTIALS: 'Готово к сборке',
  COLLECT: 'Сборка видео',
  COLLECT_ERROR: 'Ошибка сборки видео',
  COLLECTED: 'Видео собрано',
  UPLOADING_COLLECTED: 'Загрузка видео на сервер',
  READY_TO_WATCH: 'Готово к просмотру',
};

export const DEFAULT_RECORDS: any = [];

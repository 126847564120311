import Rating from '@mui/material/Rating';

import { styled } from '@mui/material/styles';
import {
  SentimentVeryDissatisfied,
  SentimentDissatisfied,
  SentimentSatisfied,
  SentimentSatisfiedAlt,
  SentimentVerySatisfied,
} from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { progkidsAPI } from '../../api/progkids';
import { useTranslation } from 'react-i18next';

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

const customIcons: {
  [key: number]: JSX.Element;
} = {
  1: <SentimentVeryDissatisfied color="error" fontSize="large" />,
  2: <SentimentDissatisfied color="error" fontSize="large" />,
  3: <SentimentSatisfied color="warning" fontSize="large" />,
  4: <SentimentSatisfiedAlt color="success" fontSize="large" />,
  5: <SentimentVerySatisfied color="success" fontSize="large" />,
};

function IconContainer(props: any) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value]}</span>;
}

const RateLesson = () => {
  const { t } = useTranslation();
  const [lessonId, setLessonId] = useState('');
  const [rating, setRating] = useState(0);

  useEffect(() => {
    progkidsAPI
      .getPrevLessonRating()
      .then((data) => {
        setLessonId(data.id);
        setRating(data.rating);
      })
      .catch(() => {});
  }, []);

  const onSetNewRating = (_: any, newValue: number | null) => {
    if (!newValue) return;

    setRating(newValue);
    progkidsAPI.setPrevLessonRating(lessonId, newValue).catch(() => {});
  };

  if (!lessonId) return null;

  return (
    <div style={{ textAlign: 'center', marginTop: 40 }}>
      <p>
        <b>{t('like_question')}</b>
      </p>
      <StyledRating
        size="large"
        value={rating}
        onChange={onSetNewRating}
        name="customized-icons"
        IconContainerComponent={IconContainer}
        highlightSelectedOnly
      />
    </div>
  );
};

export default RateLesson;

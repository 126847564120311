import { configureStore } from '@reduxjs/toolkit';

import authReducer from './auth';
import roomReducer from './room';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    room: roomReducer,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import Jitsi from 'lib-jitsi-meet';

import { JITSI_DOMAIN } from './constants';
import { logEvent } from '../../utils/debug';
import {
  getJitsiCameraTrack,
  getJitsiMicroTrack,
  getJitsiScreenTrack,
} from '../../utils/media';
import { JitsiConference } from '../../types/Jitsi';
import Bugsnag from '@bugsnag/js';
import { roomSocket } from '../../socket';
import { showErrorToast } from '../../utils/toasts';
import JitsiConnection from 'JitsiConnection';

export const getConnectionToJitsiServer = () => {
  logEvent({
    event: 'new_jitsi_connection',
  });
  const connection: JitsiConnection = new Jitsi.JitsiConnection(
    undefined,
    null,
    {
      hosts: { domain: JITSI_DOMAIN, muc: `muc.${JITSI_DOMAIN}` },
      bosh: `https://${JITSI_DOMAIN}/http-bind`,
      serviceUrl: `wss://${JITSI_DOMAIN}/xmpp-websocket`,
    }
  );

  return connection;
};

export const getRealDeviceId = (
  deviceId: string,
  devices: MediaDeviceInfo[],
  kind: MediaDeviceKind
) => {
  if (deviceId !== 'default') return deviceId;

  const kindDevices =
    new Array(...devices).filter((x: any) => x.kind === kind) || [];

  const defaultDevice = kindDevices.find((x) => x.deviceId === 'default');
  if (!defaultDevice) return '';

  return (
    kindDevices.find(
      (x) => x.deviceId !== 'default' && defaultDevice.groupId === x.groupId
    )?.deviceId || ''
  );
};

export const getLocalCameraTrack = (jitsiRoom?: JitsiConference | null) => {
  return jitsiRoom?.getLocalVideoTracks().find((x) => x.videoType === 'camera');
};

export const getLocalScreenTrack = (jitsiRoom?: JitsiConference | null) => {
  return jitsiRoom
    ?.getLocalVideoTracks()
    .find((x) => x.videoType === 'desktop');
};

const onError = (e: any) => {
  showErrorToast(e.message);
  Bugsnag.notify(e);
  roomSocket.emit('BE-send-command', {
    command: 'show-info',
    excludeSelf: true,
    message: e.message,
  });
};

export const addNewMicroTrack = (
  room: JitsiConference,
  micDeviceId: string
) => {
  logEvent({
    event: 'add_micro_track_start',
    data: { deviceId: micDeviceId },
  });

  getJitsiMicroTrack({ micDeviceId })
    .then(async (newTrack) => {
      if (!newTrack) return;
      logEvent({
        event: 'add_micro_track',
        data: { label: newTrack.track.label, deviceId: micDeviceId },
      });
      await room.getLocalAudioTrack()?.dispose();
      await room.addTrack(newTrack);
    })
    .catch(onError);
};

export const addNewCameraTrack = (
  room: JitsiConference,
  cameraDeviceId: string
) => {
  getJitsiCameraTrack({ cameraDeviceId })
    .then(async (newTrack) => {
      if (!newTrack) return;
      await getLocalCameraTrack(room)?.dispose();
      await room.addTrack(newTrack);
      logEvent({
        event: 'add_camera_track',
        data: { label: newTrack.track.label, deviceId: cameraDeviceId },
      });
    })
    .catch(onError);
};

export const addNewScreenTrack = async (
  room: JitsiConference,
  options: any
) => {
  try {
    const newTrack = await getJitsiScreenTrack(options);
    if (!newTrack) return;
    await getLocalScreenTrack(room)?.dispose();
    if (newTrack) await room.addTrack(newTrack);
    logEvent({
      event: 'add_screen_track',
      data: { label: newTrack.track.label },
    });
  } catch (error) {
    onError(error);
  }
};

export const getUserRegion = (countryByPhone?: string, meetRegion?: string) => {
  if (meetRegion) return meetRegion;

  if (countryByPhone === 'Россия') return 'eu-east';
  if (countryByPhone === 'Израиль') return 'me-north';

  return 'eu-east';
};

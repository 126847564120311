import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en_ns from './en.json';
import ru_ns from './ru.json';

export const defaultNS = 'main';

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        main: en_ns,
      },
      ru: {
        main: ru_ns,
      },
    },
    defaultNS,
    fallbackLng: 'en',
  });

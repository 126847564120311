import axios from 'axios';
import { User } from '../types/user';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_WEB_SERVER,
  withCredentials: true,
});

const isUserLoggedIn = async (): Promise<User | null> => {
  const { data } = await axiosInstance.post(`/user/loggedin`);

  return data;
};

const getPrevLessonRating = async () => {
  const { data } = await axiosInstance.get(`/lesson/rating`);

  return data;
};

const setPrevLessonRating = async (lessonId: string, value: number) => {
  const { data } = await axiosInstance.post(`/lesson/rating`, {
    lessonId,
    value,
  });

  return data;
};

const getNextLesson = async (): Promise<{ meetLink: string } | null> => {
  const { data } = await axiosInstance.get(`/lesson/next_for_launcher`);

  return data;
};

export const progkidsAPI = {
  isUserLoggedIn,
  getPrevLessonRating,
  setPrevLessonRating,
  getNextLesson,
};

import { Slider } from '@mui/material';
import { useRef, useState } from 'react';

const TestPage = () => {
  const [recording, setRecording] = useState(false);
  const [volume, setVolume] = useState(0);
  const mediaRecorder = useRef<MediaRecorder | null>(null);

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const audioContext = new AudioContext();

    mediaRecorder.current = new MediaRecorder(stream);
    mediaRecorder.current.start();
    setRecording(true);

    const source = audioContext.createMediaStreamSource(stream);
    const analyser = audioContext.createAnalyser();

    source.connect(analyser);
    analyser.connect(audioContext.destination);

    analyser.fftSize = 256;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    function draw() {
      analyser.getByteFrequencyData(dataArray);

      let values = 0;
      const length = dataArray.length;
      for (let i = 0; i < length; i++) {
        values = Math.max(values, dataArray[i]);
      }

      setVolume(values);
      requestAnimationFrame(draw);
    }

    draw();
  };

  return (
    <div style={{ maxWidth: 600, margin: 'auto', textAlign: 'center' }}>
      <h1>Test Microphone</h1>
      <Slider value={volume} min={0} max={255} />
      <button onClick={startRecording} disabled={recording}>
        TEST
      </button>
    </div>
  );
};

export default TestPage;

import { useMemo, VFC } from 'react';
import { Avatar } from '@mui/material';

import { AvatarWrap, Root, UserMeetName } from './styled';
import { MockUserCardProps } from './interfaces';
import { AVATAR_COLORS, AVATAR_SCALE } from './constants';

const MockUserCard: VFC<MockUserCardProps> = ({ userMeetName }) => {
  const name = userMeetName
    ? userMeetName
        .split(' ')
        .slice(0, 2)
        .map((item) => item[0])
        .join(' ')
    : '';

  const backgroundColor = useMemo(
    () => AVATAR_COLORS[Math.round(Math.random() * AVATAR_COLORS.length)],
    []
  );

  return (
    <Root>
      <AvatarWrap>
        <Avatar
          style={{
            width: `${9 * AVATAR_SCALE}%`,
            height: `${16 * AVATAR_SCALE}%`,
            margin: '0 auto',
            backgroundColor,
          }}
        >
          <UserMeetName>{name}</UserMeetName>
        </Avatar>
      </AvatarWrap>
    </Root>
  );
};

export default MockUserCard;

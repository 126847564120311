import { DrawLineProps } from './interfaces';

export const drawLine = ({
  canvasRef,
  originalMousePosition,
  newMousePosition,
  color = 'red',
  lineWidth = 5,
  scale = { x: 1, y: 1 },
}: DrawLineProps) => {
  const context = canvasRef.current?.getContext('2d');
  if (!context) return;

  context.strokeStyle = color;
  context.lineJoin = 'round';
  context.lineWidth = lineWidth;

  context.beginPath();
  context.moveTo(
    originalMousePosition.x * scale.x,
    originalMousePosition.y * scale.y
  );
  context.lineTo(newMousePosition.x * scale.x, newMousePosition.y * scale.y);

  context.closePath();
  context.stroke();
};

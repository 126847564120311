import { ButtonColors } from './interfaces';

const colors = {
  red: 'red',
  black: 'black',
  white: 'white',
  grey: 'grey',
  blue: 'blue',
  transparent: 'transparent',
};

export const WHITE_BUTTON: ButtonColors = {
  icon: colors.white,
  border: colors.white,
  background: colors.transparent,
};

export const BLACK_BUTTON: ButtonColors = {
  icon: colors.black,
  border: colors.black,
  background: colors.white,
};

export const RED_BUTTON: ButtonColors = {
  icon: colors.white,
  border: colors.red,
  background: colors.red,
};

export const GREY_BUTTON: ButtonColors = {
  icon: colors.white,
  border: colors.white,
  background: colors.grey,
};

import { Tooltip } from '@mui/material';
import { FC } from 'react';

import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import { JitsiStats } from '../../../types/Jitsi';

interface Props {
  userId: string;
  stats?: JitsiStats;
  localStats?: JitsiStats;
}

const ConnectionStats: FC<Props> = ({ userId, stats, localStats }) => {
  if (!stats) return null;
  if (!localStats) return null;

  const { bandwidth = {}, bitrate = {}, packetLoss = {} } = stats;
  const { resolution = {}, framerate = {} } = localStats;

  const Stats = (
    <div>
      <table>
        <tbody>
          <tr>
            <td>Qualty</td>
            <td>{stats.connectionQuality}</td>
          </tr>
          <tr>
            <td>Bandwidth</td>
            <td>
              {String(bandwidth.download) + ' / ' + String(bandwidth.upload)}
            </td>
          </tr>
          <tr>
            <td>Bitrate</td>
            <td>{String(bitrate.download) + ' / ' + String(bitrate.upload)}</td>
          </tr>
          <tr>
            <td>Packet loss</td>
            <td>
              {String(packetLoss.download) + ' / ' + String(packetLoss.upload)}
            </td>
          </tr>
          <tr>
            <td>Resolution</td>

            <td>
              <pre>{JSON.stringify(resolution[userId] || {}, null, 2)}</pre>
            </td>
          </tr>
          <tr>
            <td>Framerate</td>
            <td>
              <pre>{JSON.stringify(framerate[userId] || {}, null, 2)}</pre>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  let color = 'lightgreen';
  if (stats.connectionQuality < 80) color = 'orange';
  else if (stats.connectionQuality < 50) color = 'red';

  return (
    <Tooltip title={Stats}>
      <SignalCellularAltIcon
        htmlColor={color}
        style={{ margin: 8, zIndex: 4, position: 'absolute', display: 'block' }}
      />
    </Tooltip>
  );
};

export default ConnectionStats;

import { ChangeEvent, useEffect, useRef, useState, VFC } from 'react';
import { Button, TextField } from '@mui/material';

import { roomSocket } from '../../../../../socket';
import { getSignedUrl } from '../../../../../api/fileUpload';
import axios from 'axios';
import { showErrorToast } from '../../../../../utils/toasts';
import { useTranslation } from 'react-i18next';

const Form: VFC = () => {
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const [file, setFile] = useState<File | undefined>();
  const inputRef = useRef<HTMLInputElement>(null);

  const send = () => {
    const text = message;
    if (!text.trim()) {
      return;
    }
    setMessage('');

    roomSocket.emit('BE-chat-send', { text });
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFile((event.target.files || [])[0]);
  };

  useEffect(() => {
    if (!file) return;

    getSignedUrl({
      name: file.name,
      type: file.type,
    }).then(async ({ putUrl, downloadUrl }) => {
      try {
        await axios.put(putUrl, file, {
          headers: { 'Content-Type': file.type },
        });
        const text = `[${t('download_file')} **${file.name}**](${downloadUrl})`;
        roomSocket.emit('BE-chat-send', { text });
      } catch (error) {
        showErrorToast('Error');
      }

      const { current } = inputRef;
      if (current) current.value = '';
      setFile(undefined);
    });
  }, [file, t]);

  return (
    <>
      <TextField
        label={t('message')}
        multiline
        rows={3}
        value={message}
        variant="outlined"
        onChange={({ target: { value } }) => {
          setMessage(value);
        }}
        style={{ background: 'white', width: '100%', borderRadius: '5px' }}
        onKeyDown={(ev) => {
          if (ev.key === 'Enter' && !ev.shiftKey) {
            ev.preventDefault();
            send();
          }
        }}
      />
      <Button onClick={send}>{t('send')}</Button>
      <label htmlFor="upload-photo">
        <input
          style={{ display: 'none' }}
          id="upload-photo"
          name="file"
          type="file"
          ref={inputRef}
          onChange={handleInputChange}
        />

        <Button component="span">{t('upload')}</Button>
      </label>
    </>
  );
};
export default Form;

import { VFC } from 'react';
import { IconButton } from '@mui/material';
import {
  PersonPin,
  Fullscreen,
  EditSharp,
  BrushSharp,
  LayersClear,
} from '@mui/icons-material';
import { ControlsProps } from './interfaces';

const Controls: VFC<ControlsProps> = ({
  toggleMainVideo,
  canPaint,
  setPaintType,
  toggleFullScreen,
  clearPainting,
  paintType,
}) => {
  const isPencil = paintType === 'pencil';
  const isBrush = paintType === 'brush';

  return (
    <div style={{ pointerEvents: 'auto' }}>
      {Boolean(toggleMainVideo) && (
        <IconButton onClick={toggleMainVideo}>
          <PersonPin
            htmlColor="limegreen"
            style={{ border: '2px solid limegreen', borderRadius: 4 }}
          />
        </IconButton>
      )}
      {Boolean(toggleFullScreen) && (
        <IconButton onClick={toggleFullScreen}>
          <Fullscreen
            htmlColor="limegreen"
            style={{ border: '2px solid limegreen', borderRadius: 4 }}
          />
        </IconButton>
      )}
      {canPaint && (
        <>
          <IconButton onClick={() => setPaintType(isPencil ? '' : 'pencil')}>
            <EditSharp
              htmlColor="limegreen"
              style={{
                border: `2px solid ${isPencil ? 'red' : 'limegreen'}`,
                borderRadius: 4,
              }}
            />
          </IconButton>
          <IconButton onClick={() => setPaintType(isBrush ? '' : 'brush')}>
            <BrushSharp
              htmlColor="limegreen"
              style={{
                border: `2px solid ${isBrush ? 'red' : 'limegreen'}`,
                borderRadius: 4,
              }}
            />
          </IconButton>
          <IconButton onClick={clearPainting}>
            <LayersClear
              htmlColor="limegreen"
              style={{
                border: `2px solid limegreen`,
                borderRadius: 4,
              }}
            />
          </IconButton>
        </>
      )}
    </div>
  );
};

export default Controls;

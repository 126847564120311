import { useEffect, useRef, VFC } from 'react';

import { CanvasProps } from './interfaces';
import useSendPaint from '../../../../../hooks/paint/useSendPaint';

const Canvas: VFC<CanvasProps> = ({
  size: { width, height },
  paintType,
  receiveUserId,
  clear,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { clearCanvas } = useSendPaint({
    canvasRef,
    paintType,
    receiveUserId,
  });

  useEffect(() => {
    if (clear) clearCanvas('brush');
  }, [clear, clearCanvas]);

  return (
    <canvas
      width={width}
      height={height}
      ref={canvasRef}
      style={{ cursor: paintType ? 'pointer' : 'auto' }}
    />
  );
};

export default Canvas;

import { IconButton, TableCell, TableRow } from '@mui/material';
import moment from 'moment';
import { FC } from 'react';
import { MeetRecord } from '../../api/models/meetRecords';

import { Link } from 'react-router-dom';
import { DATE_FORMAT, STATUSES } from './constants';

import ViewIcon from '@mui/icons-material/Visibility';
import VideocamIcon from '@mui/icons-material/Videocam';

interface Props {
  record: MeetRecord;
}

const Row: FC<Props> = ({ record }) => {
  const duration = moment.duration(
    moment(record.recordEndDate).diff(moment(record.recordStartDate))
  );

  const participants = Object.keys(record.partialsRecords || {}).length;
  const isCompleted = record.status === 'UPLOADED_PARTIALS';

  const stats: any = {};
  Object.keys(record.partialsRecords || {}).forEach((key) => {
    const p = (record.partialsRecords || {})[key as any];
    const res = {
      camLength: p.camera?.links?.length || 0,
      screenLength: p.screen?.links?.length || 0,
      micLength: p.micro?.links?.length || 0,
    } as any;
    stats[key] = res;
  });

  return (
    <>
      <TableRow style={{ backgroundColor: '#ddd' }}>
        <TableCell component="th" scope="row">
          {record._id}
        </TableCell>
        <TableCell component="th" scope="row">
          {record.roomId}
        </TableCell>
        <TableCell component="th" scope="row">
          {moment(record.recordStartDate).format(DATE_FORMAT)}
        </TableCell>
        <TableCell component="th" scope="row">
          {record.recordEndDate
            ? moment(record.recordEndDate).format(DATE_FORMAT)
            : ''}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          style={{
            color: duration.asMinutes() < 55 && isCompleted ? 'red' : 'black',
          }}
        >
          {duration.asMinutes().toFixed(0)}
        </TableCell>
        <TableCell component="th" scope="row">
          {
            // @ts-ignore
            STATUSES[record.status] || record.status
          }
        </TableCell>
        <TableCell component="th" scope="row">
          <IconButton
            component={Link}
            to={`/records/${record._id}`}
            target="_blank"
          >
            <ViewIcon />
          </IconButton>
          {!isCompleted && (
            <IconButton
              component={Link}
              to={`/view/${record.roomId}`}
              target="_blank"
            >
              <VideocamIcon />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <div
            style={{
              color: participants < 2 && isCompleted ? 'red' : 'black',
            }}
          >
            Участники: {participants}
          </div>

          {Object.keys(stats).map((userId) => (
            <div>
              {userId}: cam {stats[userId].camLength}; screen{' '}
              {stats[userId].screenLength}; mic {stats[userId].micLength};
            </div>
          ))}
          <br />
        </TableCell>
      </TableRow>
    </>
  );
};

export default Row;

import styled from 'styled-components';
import { TextField as MuiTextField } from '@mui/material';

export const Root = styled.div`
  margin: 10px auto;
  height: 100vh;
  color: black;
  display: grid;
  grid-template-rows: 1fr 100px 1fr;
`;

export const SettingsWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  max-width: 500px;
  height: 100%;
  margin: 0 auto;
`;

export const TextField = styled(MuiTextField)``;

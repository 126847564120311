class Ping {
  favicon;

  constructor(options: { favicon: string }) {
    this.favicon = options.favicon || '/favicon.ico';
  }

  async ping(source: string, times: number = 10) {
    const results = [];
    let min = Number.MAX_VALUE;
    let max = Number.MIN_VALUE;

    for (let i = 0; i < times; i++) {
      await new Promise((ok) => setTimeout(ok, 500));

      const time = await new Promise<number>((ok) => {
        const img = new Image();
        var start = Date.now();
        img.onload = () => ok(Date.now() - start);
        img.onerror = () => ok(Date.now() - start);
        img.src = source + this.favicon + '?' + +new Date();
      });

      if (time < min) min = time;
      if (time > max) max = time;
      results.push(time);

      await new Promise((ok) => setTimeout(ok, 500));
    }
    results.sort();

    const median = results[times / 2];

    return { min, max, median };
  }
}

export default Ping;

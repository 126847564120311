import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Logo from '../../logo.svg';
import { RootState } from '../../store';
import { BtnsWrap, Img, InputWrap, Root } from './styled';
import { isViewRecordsAllowed } from '../../utils/permission';
import { useTranslation } from 'react-i18next';
import { logout } from '../../store/auth';
import { progkidsAPI } from '../../api/progkids';

const Home: VFC = () => {
  const { t } = useTranslation();
  const [roomId, setRoomId] = useState('');
  const [nextLessonLink, setNextLessonLink] = useState<string | undefined>();
  const user = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    user
      ? progkidsAPI
          .getNextLesson()
          .then((lesson) => setNextLessonLink(lesson?.meetLink))
          .catch(() => {})
      : setNextLessonLink(undefined);
  }, [user]);

  const onLogoutClick = () => dispatch<any>(logout());

  return (
    <Root>
      <Img src={Logo} alt="ProgKids" />
      {user && (
        <Card style={{ marginBottom: 20 }}>
          <CardContent>
            <Typography>
              {t('loggedin_as')} {user.realname}
            </Typography>
            <Button onClick={onLogoutClick}>{t('sign_out')}</Button>
          </CardContent>
        </Card>
      )}
      <InputWrap>
        <TextField
          placeholder={t('room_id')}
          variant="outlined"
          value={roomId}
          onChange={(e) => setRoomId(e.target.value)}
        />
      </InputWrap>
      <BtnsWrap>
        <Button
          component={Link}
          to={`/room/${roomId}`}
          variant="outlined"
          disabled={!roomId}
        >
          {t('start_converstation')}
        </Button>

        {!user && (
          <Button component={Link} to="/login" variant="outlined">
            {t('sign_in')}
          </Button>
        )}

        {nextLessonLink && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.open(nextLessonLink, '_self')}
          >
            {t('join')}
          </Button>
        )}
        {isViewRecordsAllowed(user) && (
          <Button component={Link} to="/records" variant="outlined">
            {t('recordings')}
          </Button>
        )}
      </BtnsWrap>
    </Root>
  );
};

export default Home;

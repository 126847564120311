import { FC } from 'react';
import { Tooltip as MuiTooltip, TooltipProps } from '@mui/material';

const Tooltip: FC<TooltipProps> = ({ children, title, ...props }) => (
  <MuiTooltip
    title={<span style={{ fontSize: '16px' }}>{title}</span>}
    {...props}
  >
    {children}
  </MuiTooltip>
);

export default Tooltip;

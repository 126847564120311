import { useControls } from 'react-zoom-pan-pinch';
import { ZoomButton } from './styled';

const ZoomControls = () => {
  const { zoomIn, zoomOut, resetTransform } = useControls();

  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 2,
        right: 0,
      }}
    >
      <ZoomButton onClick={() => zoomIn()}>+</ZoomButton>
      <ZoomButton onClick={() => zoomOut()}>-</ZoomButton>
      <ZoomButton onClick={() => resetTransform()}>x</ZoomButton>
    </div>
  );
};

export default ZoomControls;

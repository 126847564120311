import { useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import useReceivePaint from '../../hooks/paint/useReceivePaint';

const ReceivingPaintCanvas = () => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize, false);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const pencilRef = useRef<HTMLCanvasElement | null>(null);
  const brushRef = useRef<HTMLCanvasElement | null>(null);
  const { userId } = useParams<any>();

  useReceivePaint({
    pencilRef,
    brushRef,
    userId,
    ...dimensions,
  });

  return (
    <>
      <canvas
        width={dimensions.width}
        height={dimensions.height}
        ref={brushRef}
        style={{ position: 'absolute' }}
      />
      <canvas
        width={dimensions.width}
        height={dimensions.height}
        ref={pencilRef}
      />
    </>
  );
};

export default ReceivingPaintCanvas;
